import React, { Component } from 'react';

export const CLASS_NO_MODAL_CLICK = 'modal-no-click';
const TOGGLER_WILL_OPEN = 'togglerWillOpen';
const TOGGLER_DID_OPEN = 'togglerDidOpen';
const TOGGLER_WILL_CLOSE = 'togglerWillClose';
const TOGGLER_DID_CLOSE = 'togglerDidClose';
const SHOULD_PREVENT_CLOSE_ACTION = 'shouldPreventCloseAction';

function isFunction(fn) {
    return typeof fn === 'function';
}

class Toggler extends Component {

    constructor(props) {
        super(props);

        this.onBodyClick = this.onBodyClick.bind(this);

        this.state = {
            isOpen: props.defaultOpen || false
        };

        [
            TOGGLER_WILL_OPEN,
            TOGGLER_DID_OPEN,
            TOGGLER_WILL_CLOSE,
            TOGGLER_DID_CLOSE,
            SHOULD_PREVENT_CLOSE_ACTION
        ].forEach((fn) => {
            if (isFunction(this[fn])) {
                this[fn] = this[fn].bind(this);
            } else if (isFunction(props[fn])) {
                this[fn] = props[fn].bind(this);
            }
        }, this);
    }

    componentDidMount() {
        if (this.state.isOpen) {
            this.bindClickListener();
        }
    }

    componentWillUnmount() {
        this.removeClickListener();
    }

    onBodyClick({ target }) {
        if (!this.state.isOpen) { return null; }

        if (isFunction(this.shouldPreventCloseAction)) {
            if (this.shouldPreventCloseAction(target) === true) { return null; }
        }

        if (this.shouldPreventPropagationByClassName(target) || this.shouldPreventPropagationByElement(target)) {
            return null;
        }

        this.close();
    }

    CLASS_NO_MODAL_CLICK = CLASS_NO_MODAL_CLICK;
    notPropagatingElements = new Set();

    open = () => {
        const done = () => {
            this.setState({ isOpen: true }, () => {
                this.bindClickListener();

                if (isFunction(this[TOGGLER_DID_OPEN])) {
                    this[TOGGLER_DID_OPEN]();
                }
            });
        };

        if (isFunction(this[TOGGLER_WILL_OPEN])) {
            this[TOGGLER_WILL_OPEN](done);
            const hasArguments = this[TOGGLER_WILL_OPEN].length;
            if (!hasArguments) {
                return done();
            }
        } else {
            done();
        }

    };

    close = () => {
        const done = () => {
            this.setState({ isOpen: false }, () => {
                this.removeClickListener();

                if (isFunction(this[TOGGLER_DID_CLOSE])) {
                    this[TOGGLER_DID_CLOSE]();
                }
            });
        };

        if (isFunction(this[TOGGLER_WILL_CLOSE])) {
            this[TOGGLER_WILL_CLOSE](done);
            const hasArguments = this[TOGGLER_WILL_CLOSE].length;
            if (!hasArguments) {
                return done();
            }
        } else {
            done();
        }

    };

    toggle = () => this.state.isOpen ? this.close() : this.open();

    bindClickListener = () => {
        if (this.props.bindBodyClickListener === false) { return; }
        document.body.addEventListener('click', this.onBodyClick);
    };

    removeClickListener = () => document.body.removeEventListener('click', this.onBodyClick);

    shouldPreventPropagationByClassName = (element) => {
        if (!element || !element.classList) { return false; }

        return element.classList.contains(this.CLASS_NO_MODAL_CLICK);
    }

    shouldPreventPropagationByElement = (element) => {
        if (!element) { return false; }

        if (!element.contains) { return false; }
        if (!this.notPropagatingElements.size) { return false; }

        if (this.notPropagatingElements.has(element)) {
            return true;
        }

        for (const el of this.notPropagatingElements) {
            if (el.contains && el.contains(element)) {
                return true;
            }
        }

        return false;
    }

    preventCloseAction = (ref) => {
        if (!ref) { return;}
        if (this.notPropagatingElements.has(ref)) { return null; }

        this.notPropagatingElements.add(ref);
    }

    render() {
        const { children } = this.props;

        const childrenType = typeof children;
        if (childrenType !== 'undefined' && childrenType !== 'function') {
            throw new TypeError('Toggler: `children` must be a function');
        }

        const { close, open, toggle, CLASS_NO_MODAL_CLICK, preventCloseAction } = this;
        const { isOpen } = this.state;

        const renderFunc = this.toggleContent ? this.toggleContent.bind(this) : children;

        if (!renderFunc) {
            return null;
        }

        return renderFunc({ isOpen, close, open, toggle, CLASS_NO_MODAL_CLICK, preventCloseAction });
    }
}

Toggler.defaultProps = {
    defaultOpen: false
};

export default Toggler;
